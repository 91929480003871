<template>
    <div id="signout-form" :class="{signoutFormActive: active}" :style="{ top: top, left: left}">
        <p class="err-msg">
            Are you sure to Signout?
        </p>                
        <div class="buttons">
            <button @click="signout">Yes</button>
            <button @click="close">No</button>
        </div>
        <button id="close-button" @click="close">&#10005;</button>
    </div>
</template>
<script>
//import Singleton from '../singleton';
export default {
    data(){
        return {
            
        }
    },
    methods:{
        close(){
            this.$store.commit('signoutFormActive', false);
            this.username ='';
            this.password ='';
        },
        signout(){
            const singleton = this.$store.state.singleton;

            const jsonObject = {  
                "actionId" : 2,//"SIGNOUT",                
            };   
            singleton.send(jsonObject, this.onResponse);
            singleton.send
            this.$store.commit('userId', 0);
            this.$store.commit('signoutFormActive', false);
            this.$store.state.singleton.resetSession();
        },
        onResponse(response){            
            if (!response.isSuccess()){
                this.$store.commit('signinMessage', "Failed to signout"); 
            } 
            else{
                this.$store.commit('signinFormActive', false);
                this.$store.commit('userId', 0);                
            } 
        }
    },
    computed: {        
        active(){
            return this.$store.state.signoutFormActive;
        },
        top(){
            return `${this.$store.state.eventFormPosY}px`;
        },
        left(){
            return `${this.$store.state.eventFormPosX - 250}px`;
        },
    },
    directives: {        
        focus:{
            update(el){                
                el.focus();
            }
        }
    }
}
</script>

<style scoped>
    #signout-form{
        display: none;
        box-shadow: 0 2px 4px #ddd;
        width: 250px;
        max-width: 90%;
        z-index: 10;
        padding: 2px;
        background-color: white;
        border: 1px  #ddd solid;
        color: black;
    } 

    .signoutFormActive{
        position: absolute;
        display: block !important;
        z-index: 10;
    }

    #signout-form .buttons{
        text-align: left;
    }
    #signout-form .err-msg{
        position: relative;
        padding: 0;
        margin: 0;
        color: black;
        font-size: 10px;
        color: red;
    }
</style>